<template>
  <div>
    <!-- 未激活 -->
    <div v-if="active == 0">
      <div class="class_tabs">
        <div class="tabsList">激活学习卡</div>
      </div>
      <!-- 表单 -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="学习卡卡号：" label-width="125px" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入学习卡号"
          ></el-input>
        </el-form-item>
        <el-form-item label="学习卡密码：" label-width="125px" prop="region">
          <el-input
            v-model="ruleForm.region"
            placeholder="请输入学习卡密码"
          ></el-input>
        </el-form-item>
        <div class="yesBtn" @click="submitForm('ruleForm')">确定</div>
      </el-form>
    </div>
    <!-- 已激活 -->
    <div v-else>
      <div class="class_tabs">
        <div class="tabsList">我的学习卡</div>
      </div>

      <div class="flex cardList">
        <div class="my_card common" v-for="(item, index) in card" :key="index">
          <div class="card_top">
            <img src="../../assets/img/personal/logo2.png" alt />
            <div>使用中</div>
          </div>
          <div class="card_num">{{ item.number }}</div>
          <div class="card_time">
            <!-- <div>结束时间</div> -->
            <div></div>
            <div>开卡时间</div>
          </div>
          <div class="card_time">
            <div></div>
            <div>{{ (item.carding_time * 1000) | dateFormat }}</div>
            <!-- <div>2021-02-26</div> -->
          </div>
          <!-- <div class="card_time">
          <div>持卡人姓名</div>
          </div>-->
        </div>
        <div class="addCard flex2 common" @click="addNew()">
          <div class="addImg">
            <img src="../../assets/img/personal/img_5_8.png" />
          </div>
          添加学习卡
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addCard, getCard } from "../../assets/js/personal";
export default {
  data() {
    return {
      card: [],
      active: 1,
      ruleForm: {
        name: "",
        region: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入学习卡号", trigger: "blur" },
          {
            min: 16,
            max: 18,
            message: "长度在 16 到 18 个字符",
            trigger: "blur",
          },
        ],
        region: [
          { required: true, message: "请输入学习卡密码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getCard();
  },
  methods: {
    // 获取学习卡
    getCard() {
      getCard({
        user_id: JSON.parse(window.localStorage.getItem("userInfo"))
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.card = res.data;
          this.card.map((x) => {
            x.number = x.number + "";
            x.number = x.number.substr(0, 4)    + "    ****   ****    " +    x.number.substr(12, 15);
          });
        }
      });
    },
    // 绑定学习卡
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addCard({
            siteId: window.localStorage.getItem("siteId"),
            user_id: JSON.parse(window.localStorage.getItem("userInfo"))
              ? JSON.parse(window.localStorage.getItem("userInfo")).id
              : "",
            number: this.ruleForm.name,
            carmi: this.ruleForm.region,
          }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.$message.success(res.msg);
              this.active = 1;
              this.ruleForm = {};
            }
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    addNew() {
      this.active = 0;
    },
  },
};
</script>
<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
/deep/ input::-webkit-input-placeholder {
  color: #999;
  font-size: 14px;
}
/deep/ input::-moz-input-placeholder {
  color: #999;
  font-size: 14px;
}
/deep/ input::-ms-input-placeholder {
  color: #999;
  font-size: 14px;
}
.class_tabs {
  width: 930px;
  border-bottom: 1px solid #dddddd;
  .tabsList {
    width: 120px;
    font-size: 16px;
    color: #0c69e6;
    height: 64px;
    line-height: 70px;
    padding: 0 20px;
    box-sizing: border-box;
    // margin-bottom: 10px;
    border-bottom: 4px solid #0c6ae7;
    // padding-bottom: 10px;
    // box-sizing: border-box;
  }
}
.el-form {
  padding-top: 70px;
  box-sizing: border-box;
}
.el-input {
  width: 400px;
  height: 46px;
  background-color: #f1f1f2;
  border: none;
  border-radius: 6px;
  overflow: hidden;
}
/deep/ .el-input__inner {
  background-color: #f1f1f2;
  border: none;
  height: 46px;
  line-height: 46px;
}
.yesBtn {
  cursor: pointer;
  width: 120px;
  height: 36px;
  background: #0090f0;
  border-radius: 18px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 36px;
  margin-left: 130px;
}
.cardList {
  margin: 30px 25px;
  flex-wrap: wrap;
}
.common {
  width: 293px;
  height: 166px;
  margin-right: 60px;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 15px;
  box-sizing: border-box;
  color: #fff;
}
.my_card {
  background: linear-gradient(to right, #020101, #333333);
  box-shadow: 0px 4px 8px 0px rgba(14, 78, 230, 0.38);

  .card_top {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    img {
      width: 42px;
      height: 23px;
    }
  }
  .card_num {
    font-size: 18px;
    text-align: center;
    margin: 25px 0;
  }
  .card_time {
    font-size: 12px;
    color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
}
.addCard {
  cursor: pointer;
  background-color: #f2f2f2;
  color: #999999;
  font-size: 18px;
  font-weight: 500;
  .addImg {
    width: 80px;
    height: 80px;
    margin-bottom: 13px;
  }
}
</style>
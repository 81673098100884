import { render, staticRenderFns } from "./studyCard.vue?vue&type=template&id=248b8537&scoped=true&"
import script from "./studyCard.vue?vue&type=script&lang=js&"
export * from "./studyCard.vue?vue&type=script&lang=js&"
import style0 from "./studyCard.vue?vue&type=style&index=0&id=248b8537&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248b8537",
  null
  
)

export default component.exports